<template>
    <div class="wraper" >
        <Header :isShow="isShow" />
        <div class="banner">
            <img src="@/assets/images/join_banner.jpg" alt="" />
        </div>
        <div class="content max_big_box" ref="header" >
             <div class="title max_small_box">
                <p >加入我们</p>
            </div>
            <div class="line"></div>
            <a-table class="max_small_box" :columns="columns" :data-source="data" rowKey='id' :pagination="false" :scroll="{ x: 600, y: 300 }">
                <template #action="{ record }">
                <a @click="gotoDetail(record)">查看详情</a>
                </template>
            </a-table>
            <div class="pagination max_small_box">
                <a-pagination :default-current="page" :total="last_page" @change="handleChangePage" />
            </div>
            <!-- <div class="line"></div> -->
        </div>
    </div>
</template>

<script>
import { onUnmounted, onMounted, ref, watchEffect, defineComponent  } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { get, post } from '../../utils/request';
import * as api from '../../utils/api';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import FloatingFrame from '../../components/floatingFrame/FloatingFrame';

//页面滚动处理函数
const ScrollEffect = () => {

    const isShow = ref()

    const handleScroll = (height) => {
        
        if(document.documentElement.scrollTop >= height){
            isShow.value = 1
        }else{
            isShow.value = 2
        }
    }

    return { handleScroll, isShow }
}

//详情处理模块
const detailEffect = () => {
    const router = useRouter();
    //页数
    const page = ref(1)

    //总页数
    const last_page = ref()

    const columns = [
        {
            title: '发布时间',
            dataIndex: 'createtime',
            key: 'createtime',
        },
        {
            title: '招聘职位',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '所属部门',
            dataIndex: 'department',
            key: 'department',
        },
        { title: '操作', dataIndex: '', key: 'Action', slots: { customRender: 'action' } },
    ];

    const data = ref()

    //跳转详情
    const gotoDetail = (record) => {
        // router.push({path:'/join_detail/'+record.id , params:{id:record.id}})
        router.push({ path: `/join_detail/${record.id}` })
        // console.log(record)
    }

    const getList = async () => {
        let postData = {
            page: page.value,
        }
        try{
            await post('/index/get_recruitment', postData).then((res) => {
                // console.log('加入我们列表', res)
                data.value = res.data.data
                last_page.value = res.data.total
            })
            
        }catch(e) {
        
        }
    }

    //更换页数
    const handleChangePage = (new_page, pageSize) =>{
        page.value = new_page
        getList()
    }

    watchEffect(() =>{
        getList()
    })

    return { getList, page, last_page, handleChangePage, columns, data, gotoDetail }
}

export default defineComponent ({
    name: 'Join',
    components: { Header, Footer, FloatingFrame },
    methods: {
        click1(record, index){
            return {
                on: {
                    click: () => {
                        // console.log(11111)
                    }
                }
            }
        }
    },
    setup() {
        const route = useRoute()
        //页面滚动处理函数
        const { 
            handleScroll, 
            isShow 
        } = ScrollEffect()

        //详情处理模块
        const {page, last_page, handleChangePage, columns, data, gotoDetail } = detailEffect()

        //获取滑块到顶部的距离
        const header = ref(null)

        onMounted(() => {
            document.documentElement.scrollTop = document.body.scrollTop =0;
            window.addEventListener('scroll', function () {
                if(header.value){
                    handleScroll(header.value.offsetTop);
                }
            })
        });

        onUnmounted(() => {
            document.querySelector('body').setAttribute('style', 'background-color:#fff')
        })


        const { imgUrl }  = api

        return { imgUrl, isShow, header, page, last_page, handleChangePage, columns, data, gotoDetail }
    }
})
</script>

<style lang="scss" scoped>
@import '../../style/viriables.scss';
/* 轮播图 */
.banner{
    margin-top: 111px;
    @media screen and (max-width: 1200px){
        margin-top: $phone-banner-marginTop;
        height: $phone-banner-height;
    }
    img{
        width: 100%;
        display: block;
        @media screen and (max-width: 1200px){
            height: 100%;
            object-fit: cover;
        }
    }
}
.content{
    margin-top: 50px;
    .title{
        text-align: center;
        @media screen and (max-width: 1200px){
            padding: 0 30px;
        }
        p{
            color: rgba(16, 16, 16, 100);
            font-size: 20px;
            font-weight: bold;
        }
    }
    .line{
        width: 100%;
        height: 1px;
        background: rgba(187, 187, 187, 100);
        margin-top: 30px;
    }
    .detail{
        margin: 30px;
    }
}
.pagination{
    display: block;
    text-align: center;
    padding-top: 80px;
}
</style>